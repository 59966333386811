<script>
export default {
  name: "App"
}
</script>

<template>
  <div>
    <nav class="navbar navbar-expand-lg bg-secondary text-uppercase fixed-top" id="mainNav">
      <div class="container">
        <a class="navbar-brand js-scroll-trigger" href="#page-top">Le stress relationnel</a>
        <button aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler navbar-toggler-right text-uppercase font-weight-bold bg-primary text-white rounded" data-bs-target="#navbarResponsive" data-bs-toggle="collapse" type="button">
          Menu
          <i class="fas fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item mx-0 mx-lg-1 align-self-center text-center">
              <a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="#approche">Mon approche</a>
            </li>
            <li class="nav-item mx-0 mx-lg-1 align-self-center">
              <a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="#publications">Publications</a>
            </li>
            <li class="nav-item mx-0 mx-lg-1 align-self-center">
              <a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="#actions">Actions/Tarifs</a>
            </li>
            <li class="nav-item mx-0 mx-lg-1 align-self-center">
              <a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="#parcours">Parcours</a>
            </li>
            <li class="nav-item mx-0 mx-lg-1 align-self-center">
              <a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="#contact">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <header class="masthead page-section text-center">
      <div class="container d-flex align-items-center flex-column">
        <div class="row mb-3">
          <div class="col-xs-12 col-sm-3 ml-auto">
            <img class="img-fluid" alt="Mieux communiquer" src="./assets/edifice.jpg"/>
          </div>
          <div class="col-xs-12 col-sm-auto align-text-bottom mr-auto mt-auto">
            <span class="align-bottom">
              Pour mieux communiquer...
            </span>
          </div>
        </div>

        <h1 class="masthead-heading text-uppercase mb-3">
          APPRIVOISEZ
          <div class="my-3"/>
          LE STRESS RELATIONNEL !
          <hr class="mb-2"/>
        </h1>

        <div class="row">
          <div class="col-12">
            <p class="masthead-subheading font-weight-bold mb-3 text-left">
              Quel que soit le contexte, dès que nous sommes en présence des autres, nous communiquons et parfois notre perception des êtres et des évènements nous leurre quelque peu, la relation se fragilise…
            </p>
          </div>

          <div class="col-12">
            <p class="masthead-subheading font-weight-bold mb-3 text-left">
              Avons-nous toujours conscience de la manière dont nous communiquons avec les autres ?
            </p>
          </div>

          <div class="col-12">
            <p class="masthead-subheading font-weight-bold mb-3 text-left">
              Incompréhensions, quiproquos, a priori, manque de confiance en soi, en l’autre ; jugement de valeur, interprétation, idées préconçues, craintes ou certitudes ancrées et autres distorsions de la pensée, non prise en compte de ses propres ressentis : autant d’éléments inducteurs de tensions et conflits potentiels.
            </p>
          </div>

          <div class="col-12">
            <p class="masthead-subheading font-weight-bold mb-3 text-left">
              Nous parvenons même à nous auto-stresser : même lorsque nous sommes seuls, en tête à tête avec nous-même, nos pensées s’en donnent à cœur joie pour confirmer nos raisons de stresser ou en créer de nouvelles !
            </p>
          </div>

          <div class="col-12">
            <p class="masthead-subheading font-weight-bold mb-3 text-left">
              Avons-nous toujours conscience de notre capacité à influer positivement sur le déroulement et l’issue d’un échange relationnel ?
            </p>
          </div>
        </div>
      </div>
    </header>

    <section id="approche" class="page-section bg-primary">
      <div class="container">
        <h2 class="page-section-heading align-self-center text-left text-uppercase text-secondary">
          Mon approche
        </h2>
      </div>
    </section>

    <section class="page-section">
      <div class="container d-flex align-items-center flex-column">
        <div class="row mb-3">
          <div class="col-xs-12 col-sm-7">
            <p class="mb-3 text-left">
              <span class="font-weight-bold">
                Notre vie en société présente bien des similitudes avec l’improvisation théâtrale.
              </span>
            </p>
            <p class="mb-3 text-left">
              <span class="font-weight-light">
                J’ai largement développé ce point dans mon livre
              </span>
              <span class="font-weight-light font-italic">
                « Mieux communiquer avec soi-même et avec les autres ».
              </span>
            </p>
          </div>

          <div class="col-xs-12 col-sm-5">
            <img class="img-fluid" alt="La vie est un théâtre" src="./assets/monet.jpg"/>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <p class="mb-3 text-left">
              <span class="font-weight-light">
                La synthèse de mes expériences professionnelles –les unes dans le monde du spectacle, les autres dans celui des relations humaines- me permet d’aborder la communication de façon toute personnelle, notamment en utilisant
              </span>
              <span class="font-weight-bold">
                Les techniques théâtrales
              </span>
              <span class="font-weight-light">
                comme outils pédagogiques. Associées aux bases théoriques de la communication, ces techniques allient
              </span>
              <span class="font-weight-bold">
                rigueur et plaisir du « jeu »
              </span>
              <span class="font-weight-light">
                ; elles s’avèrent de puissants moyens d’évolution personnelle et d’efficacité professionnelle.
              </span>
            </p>

            <p class="mb-3 text-left">
              <span class="font-weight-light">
                Pour rendre nos échanges les plus constructifs et harmonieux possibles, il est essentiel de prendre conscience de l’unité qui nous constitue et à partir de laquelle nous communiquons.
              </span>
            </p>

            <p class="mb-3 text-left">
              <span class="font-weight-light">
                Lors d’un échange relationnel stressant, suffirait-il de se dire
              </span>
              <span class="font-weight-light font-italic">
                « calme-toi !... »
              </span>
              <span class="font-weight-light">
                ou bien
              </span>
              <span class="font-weight-light font-italic">
                « respire !… »,
              </span>
              <span class="font-weight-light">
                pour retrouver et maintenir durablement son équilibre ?
              </span>
            </p>

            <p class="mb-3 text-left">
              <span class="font-weight-light">
                Parfois, nous laissons notre mental seul aux commandes, c’est alors qu’un conflit peut apparaitre entre vouloir et pouvoir agir…
              </span>
            </p>

            <p class="mb-3 text-left">
              <span class="font-weight-light font-italic">
                « Quand on veut, on peut ! »
              </span>
              <span class="font-weight-light">
                … pas sûr !
              </span>
            </p>

            <p class="mb-3 text-left">
              <span class="font-weight-bold">
                Nous sommes également un corps !
              </span>
            </p>

            <p class="border p-3">
              <span class="font-weight-bold">
                Le cœur de mon approche :
              </span>
              <span class="font-weight-light">
                rétablir la complicité entre corps et mental afin d’agir, en toutes circonstances, à partir de notre profonde cohérence. Découvrir et sentir que nous communiquons comme nous respirons !
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="publications" class="page-section bg-primary">
      <div class="container">
        <h2 class="page-section-heading align-self-center text-left text-uppercase text-secondary">
          Publications
        </h2>
      </div>
    </section>

    <section class="page-section">
      <div class="container d-flex flex-column">
        <div class="row mb-3">
          <div class="col-xs-12 col-sm-5">
            <img class="img-fluid" alt="" src="./assets/gereso.png"/>
          </div>
          <div class="col-xs-12 col-sm-7">
            <p class="mb-3 text-right">
              <span class="font-weight-bold">
                Mieux communiquer
              </span>
              <br/>
              <span class="font-weight-bold">
                avec soi-même et avec les autres
              </span>
              <br/>
              <span class="font-weight-light">
                Lâchez prise… Soufflez 
              </span>
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-4">
            <div class="d-flex">
              <img class="img-fluid" alt="" src="./assets/couv.jpg"/>
            </div>
            <ul>
              <div class="font-weight-bold">
                Sommaire
              </div>
              <li class="font-weight-light">
                Se situer dans l’ici et maintenant
              </li>
              <li class="font-weight-light">
                Partager la scène avec les autres
              </li>
              <li class="font-weight-light">
                Occuper sa juste place dans le jeu
              </li>
              <li class="font-weight-light">
                S’engager dans une dynamique d’action
              </li>
              <li class="font-weight-light">
                Exercices
              </li>
            </ul>
            <div class="mt-3 d-flex">
              <img class="img-fluid" alt="" src="./assets/mieuxco.png"/>
            </div>
            <p class="mb-3 text-left font-weight-light">
              2ème édition - Juin 2019
            </p>
          </div>

          <div class="col-xs-12 col-sm-8">
            <p class="mb-3 text-left font-weight-light">
              La voix et le ton, les postures et les gestes, les regards ou les silences peuvent révéler en partie notre état, notre humeur…
            </p>
            <p class="mb-3 text-left font-weight-light">
              Il nous arrive aussi d’être surpris, émus ou décontenancés par un comportement ou une parole et nous perdons pied face à l’inattendu. Suffirait-il de se dire « lâche prise ! » pour éviter de s’emporter ? Ou bien « fais-toi confiance ! » pour oser s’exprimer ?
            </p>
            <p class="mb-3 text-left font-weight-light">
              Notre mental, seul aux commandes, se révèle parfois notre pire ennemi…
            </p>
            <p class="mb-3 text-left font-weight-light">
              Alors, comment éviter de se laisser submerger par ses émotions ? Comment recréer une complicité entre corps et mental et retrouver aisance et assurance ? Ou comment être acteur parmi les autres acteurs de notre vie en société ?
            </p>
            <p class="mb-3 text-left font-weight-light">
              Pour trouver vos propres réponses, l’auteur vous invite à partager sa réflexion, qui s’appuie en partie sur l’improvisation théâtrale. Des anecdotes professionnelles, des exercices ludiques et de relaxation vous feront découvrir comment lâcher prise et souffler, afin de mieux communiquer avec soi-même et avec les autres !
            </p>

            <p class="mb-3 text-center font-weight-bold">
              Plus d’informations, résumé, sommaire complet et extrait gratuit sur :
            </p>
            <div class="d-flex">
              <div class="mx-auto btn border">
                <a href="https://www.la-librairie-rh.com/livre-efficacite-professionnelle/mieux-communiquer-avec-soi-meme-et-avec-les-autres-mico.html">
                  <img class="img-fluid" alt="" src="./assets/librh.png"/>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 border my-3">
        </div>

        <div class="row mt-3">
          <div class="col-xs-12 col-sm-4">
            <div class="d-flex">
              <div class="mx-auto">
                <img class="img-fluid" alt="" src="./assets/stressrela.png"/>
                <p class="mb-3 text-left font-weight-light">
                  Janvier 2020
                </p>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-8">
            <p class="mb-3 text-left font-weight-light">
              Mouvement, déstabilisation, rééquilibrage… La vie nous bouscule et nous met parfois à l’épreuve.
            </p>
            <p class="mb-3 text-left font-weight-light">
              Toutes ces oscillations entre calme optimal et perturbations sont communément appelées le stress !
            </p>
            <p class="mb-3 text-left font-weight-light">
              Mauvaise écoute, manque de confiance en soi et en l’autre, interprétations, craintes ou certitudes… Autant de façons de l’amplifier et de se contaminer les uns les autres !
            </p>
            <p class="mb-3 text-left font-weight-light">
              Quels sont les mécanismes du stress ? Comment reconnaitre les signes avant-coureurs ? De quelle façon l’apprivoiser pour réduire ses effets, voire les éviter ? Quels sont nos propres facteurs de stress ?
            </p>
            <p class="mb-3 text-left font-weight-light">
              Ce livre vous invite à un parcours au fil duquel vous pourrez vous approprier des outils pour agir sur le stress ressenti et influer positivement sur la relation.
            </p>
            <p class="mb-3 text-left font-weight-light">
              Découvrez comment utiliser vos propres ressources pour vous préserver du stress, mais aussi la dynamique positive qu’il peut insuffler : celle qui pousse à oser, à se dépasser, à créer.
            </p>
            <p class="mb-3 text-left font-weight-light">
              Pour se sentir bien, tout simplement !
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="actions" class="page-section bg-primary">
      <div class="container">
        <h2 class="page-section-heading align-self-center text-left text-uppercase text-secondary">
          Actions/Tarifs
        </h2>
      </div>
    </section>

    <section class="page-section">
      <div class="container d-flex align-items-center flex-column">
        <div class="row">
          <div class="col-12">
            <h2 class="mb-5">
              Accompagnements individuels…
            </h2>
            <p class="mb-3 text-left font-weight-light">
              Le stress relationnel est inhérent à notre vie en société, chacun de nous est concerné. L’apprivoiser impacte positivement notre capacité à rendre nos échanges personnels et professionnels les plus constructifs et harmonieux possibles.
            </p>
            <p class="mb-3 text-left font-weight-light">
              Je suis à l’écoute de
              <b>toute personne désirant mieux communiquer</b>
              dans les différentes sphères du quotidien - privée, publique, professionnelle-.
            </p>
            <p class="mb-3 text-left font-weight-light">
              Prendre la parole en public ; développer la confiance en soi et en l’autre ; s’exprimer avec aisance et assurance ; oser libérer la voix ; neutraliser les manifestations d’une émotion ; trouver la juste affirmation de soi ; développer l’écoute ; s’approprier les bases de la communication interpersonnelle ; mesurer l’impact des pensées sur la manière de vivre les situations stressantes…
            </p>
            <p class="mb-3 text-left font-weight-bold">
              Tous ces niveaux de la relation à l’autre se conjuguent et se renforcent mutuellement.
            </p>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-xs-12 col-sm-5">
            <img class="img-fluid" alt="" src="./assets/water.jpg"/>
            <p class="mb-3 text-left font-weight-light">
              <div class="font-weight-bold">
                Modalités
              </div>
              <ul>
                <li>
                  Entre 3 et 10 séances, généralement espacées de 15 jours. Cet intervalle permet la mise en œuvre des voies d’entraînement proposées.
                </li>
                <li>
                  Chaque séance dure 1 h. 30.
                </li>
                <li>
                  En début de chaque séance, nous faisons un point sur la progression du travail engagé et notons l’éventuelle apparition d’objectifs transversaux. A partir de cet échange et toujours d’un commun accord, les modalités de base peuvent évoluer.
                </li>
              </ul>
            </p>

            <p class="mb-3 text-left font-weight-light">
              Lieu : Longjumeau (Essonne)
              <br/>
              Tarif : 210 € la séance
              <br/>
              Règlement à l’issue de chaque séance.
              <br/>
              Une facturation d’honoraires est établie en fin d’accompagnement.
              N° SIRET 523328789 00020
            </p>
          </div>

          <div class="col-xs-12 col-sm-7">
            <p class="mb-3 text-left font-weight-light">
              Chacun de nous est unique et même si  les domaines d’entraînement cités précédemment se rejoignent, nos ressources et nos voies de progression sont, elles-aussi uniques ! Telle personne désirera se libérer d’un trac paralysant lors de ses prises de parole ; telle autre aura l’impression de ne jamais respirer « ma voix ne sort pas… » ; telle autre encore cherchera à calmer les fréquentes tensions avec ses proches ou ses collaborateurs ou bien exprimera sa difficulté à « occuper sa place » dans un groupe.
            </p>
            <p class="mb-3 text-left font-weight-light">
              L’accompagnement individuel est donc synonyme d’une approche « sur mesure ». Pour ce faire, la démarche implique un entretien préalable afin de déterminer et préciser les besoins, les attentes, le ou les objectifs de l’intéressé(e), ainsi que les diverses modalités – nombre de séances envisagées ; durée de chaque séance, lieu, tarif -.
            </p>
            <p class="mb-3 text-left font-weight-light">
              Ce premier échange permet également de s’assurer du possible engagement des deux parties – l’accompagnante et l’accompagné(e)- et d’acter cet accord.
            </p>
            <p class="mb-3 text-left font-weight-light">
              Le cadre ainsi posé permet d’installer des bases claires et sécurisantes ; néanmoins, il demeure souple. L’adaptation est constante.
            </p>
            <p class="mb-3 text-left font-weight-light">
              La confiance et la confidentialité sont les deux mots/piliers de tout accompagnement individuel.
            </p>
            <p class="mb-3 text-left font-weight-light">
              L’implication effective de l’accompagné est indispensable au bon déroulement de la démarche. En dehors de toute réelle motivation, aucun résultat ne peut être attendu.
            </p>
            <p class="mb-3 text-left font-weight-light">
              L’accompagné est libre de cesser son engagement à l’issue d’une séance.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="parcours" class="page-section bg-primary">
      <div class="container">
        <h2 class="page-section-heading align-self-center text-left text-uppercase text-secondary">
          Parcours
        </h2>
      </div>
    </section>

    <section class="page-section">
      <div class="container d-flex align-items-center flex-column">
        <div class="row">
          <div class="col-xs-12 col-sm-8">
            <p class="mb-3 text-left font-weight-light">
              L’aventure de la scène a commencé pour moi à l’âge de huit ans, dans un théâtre d’enfants, à Bordeaux. Immédiatement, j’ai su que cet univers serait le mien ! Ce domaine permet à bien des « timides » de découvrir les moyens de prendre confiance, de se dépasser… ce fut mon cas.
            </p>
            <p class="mb-3 text-left font-weight-light">
              <b>Une expérience de plus de trente années dans le monde du spectacle</b> (danse classique à l’Opéra-théâtre d’Avignon ; comédies musicales, Cie Michel Fugain...)
            </p>
          </div>
          <div class="col-xs-12 col-sm-4">
            <img class="img-fluid" alt="" src="./assets/celine.jpg"/>
          </div>
          <div class="col-12">
            <p class="mb-3 text-left font-weight-light">
              Parallèlement…
            </p>
            <p class="mb-3 text-left font-weight-light">
              Plus de <b>vingt-cinq ans d’expérience dans le domaine des sciences humaines, de la pédagogie et de la formation en entreprise</b>
              (Nombreuses références dans différents secteurs : banque, services, assurances, hôtellerie, luxe…) <b>et Grandes Ecoles</b>  (Négocia, IGS, ESIEE, Ecole Française du Barreau, HEC …)
            </p>
            <p class="mb-3 text-left font-weight-light">
              <b>Relaxologue</b>  (<b>ICH</b> –Paris-  et <b>Dr Bergès</b> –Salpêtrière-).
            </p>
            <p class="mb-3 text-left font-weight-light">
              Aujourd’hui, je me consacre principalement à des accompagnements individuels sur trois thèmes interdépendants :
              <b>affirmation de soi/communication/gestion du stress</b>.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="contact" class="page-section bg-primary">
      <div class="container">
        <h2 class="page-section-heading align-self-center text-left text-uppercase text-secondary">
          Contact
        </h2>
      </div>
    </section>

    <section class="page-section">
      <div class="container d-flex flex-column">
        <div class="row">
          <div class="col-xs-12 col-sm-6 mb-3">
            Pour me contacter :
            <br/>
            <a href="mailto:blondel.celine@free.fr">blondel.celine@free.fr</a>
            <br/>
            <a href="mailto:info@celinestage.fr">info@celinestage.fr</a>
          </div>
          <div class="col-xs-12 col-sm-6">
            Des sites amis :
            <br/>
            Bord de Scène :
            <a href="http://www.bord2scene.com" target="_blank">bord2scene.com</a>
            <br/>
            Toussaint :
            <a href="http://toussaintcurci.com" target="_blank">toussaintcurci.com</a>
            <br/>
            Christine Berthonnet :
            <a href="http://www.christrineberthonnet.fr" target="_blank">christrineberthonnet.fr</a>
          </div>
        </div>
      </div>
    </section>

    <footer class="footer text-center">
      <div class="container">
        <h2 class="mb-5 font-weight-bold text-left">
          Quelques témoignages
        </h2>
        <div class="row h4 font-weight-light">
          <div class="col-lg-4 mb-5 mb-lg-0">
            <div class="border rounded p-3">
              <p class="font-italic text-left">
                Je pensais n'avoir aucun besoin particulier et j'ai découvert quelque chose de très important : la clé, c'est moi qui la détiens !
              </p>
              <p class="align-bottom text-right">
                Khadi D.
              </p>
            </div>
          </div>
          <div class="col-lg-4 mb-5 mb-lg-0">
            <div class="border rounded p-3">
              <p class="font-italic text-left">
                J'ai rarement eu l'occasion de participer à un stage où l'animateur (« trice » en l'occurrence) arrivait aussi rapidement et avec autant de douceur à mettre les participants à l'aise et à faire qu'ils s'impliquent tous. Bravo à Céline !
              </p>
              <p class="align-bottom text-right">
                Florence P.
              </p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="border rounded p-3">
              <p class="font-italic text-left">
                Merci pour l'approche innovante de la thématique. J'ai apprécié la manière à la fois dense et ludique d'aborder le sujet ; j'ai particulièrement aimé les improvisations qui m'ont permis de repérer mes gestes, mes mots, mes attitudes qui parfois génèrent du stress… chez moi et chez les autres ! Merci à Céline pour son écoute et sa bienveillance.
              </p>
              <p class="align-bottom text-right">
                Anouk T.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <section class="copyright py-4 text-center text-white">
      <div class="container">
        <small>
          Copyright © le-stress-relationnel 2019
        </small>
      </div>
    </section>
  </div>
</template>
